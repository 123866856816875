import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { Accordion } from '@/modules/shared/components/accordion/Accordion'
import { ConfirmDialog } from '@/modules/shared/components/confirm-dialog/ConfirmDialog'
import { CatalogIcon } from '@/modules/shared/icons/CatalogIcon'
import { PlusIcon } from '@/modules/shared/icons/PlusIcon'
import SideNavLink, { SideNavLinkItem } from '@/modules/shared/layouts/side-navigation/SideNavLink'
import { SideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/PurchaserNavigation'
import { Themes } from '@/modules/shared/types/Themes'

interface CatalogsSideBarItemProps extends SideBarItem {
  catalogRoutesMatch: boolean
}

export const CatalogsSideBarItem = ({
  catalogRoutesMatch,
  openAccordionKey,
  changeOpenAccordionKey,
  onCloseDrawer,
  sideBarIndexKey,
}: CatalogsSideBarItemProps) => {
  const { t } = useTranslation()
  const { userPermissions } = useSessionUser()
  const [comingSoon, setComingSoon] = useState(false)

  const getSideNavLinkItems = () => {
    const sideNavItems: SideNavLinkItem[] = [
      {
        key: t('general.newCatalog', 'New Catalog'),
        icon: <PlusIcon className="size-6 text-gray-500" />,
        onClick: () => {
          setComingSoon(true)
          onCloseDrawer()
        },
      },
    ]

    if (userPermissions?.SUPPLIER_CAN_MANAGE_CATALOGS) {
      sideNavItems.push({
        key: t('general.viewAll', 'View All'),
        target: '/catalogs',
      })
    }

    return sideNavItems
  }

  return (
    <>
      <Accordion
        titlePrefixIcon={<CatalogIcon className="size-7" />}
        title={t('general.catalogs', 'Catalogs')}
        activeColor={catalogRoutesMatch ? Themes.Primary : undefined}
        defaultOpen={openAccordionKey === sideBarIndexKey}
        handleTriggerClick={() => changeOpenAccordionKey(sideBarIndexKey)}
        buttonClassName="min-h-11 px-3"
        keepActiveColor
      >
        <SideNavLink
          onCloseDrawer={() => onCloseDrawer()}
          testId="catalogs-navigation"
          sideNavLinkItems={getSideNavLinkItems()}
        />
      </Accordion>
      <ConfirmDialog
        theme={Themes.Primary}
        isOpen={comingSoon}
        setIsOpen={setComingSoon}
        title={t('general.comingSoon', 'Coming Soon!')}
        description={t(
          'general.createCatalogComingSoon',
          "Soon you will be able to create new Catalogs here, but we're still working on it."
        )}
        primaryButtonLabel={t('general.okayGotIt', 'Okay, Got It')}
        onPrimaryBtnClick={() => setComingSoon(false)}
      />
    </>
  )
}
