import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PurchasingBarcodeFragmentDoc } from './Barcode.generated'
import { PurchasingPageInfoFragmentDoc } from './PageInfo.generated'
import { ProductFragmentDoc } from './Product.generated'
import { PurchasingSupplierDetailByLineFragmentDoc } from './SupplierDetailByLine.generated'
import * as Types from './purchasing_graphql'

export const GetLegacyPricedCatalogueDocument = gql`
  query GetLegacyPricedCatalogue(
    $pricedCatalogueId: Int
    $after: String
    $before: String
    $filter: RansackFilterType
    $sort: [RansackSortType!]
    $first: Int
    $last: Int
  ) {
    currentPurchaser {
      id
      pricedCatalogue(id: $pricedCatalogueId) {
        id
        title
        updatedAt
        createdAt
        contracted
        user {
          id
          fullName
        }
        owner {
          id
          ... on Supplier {
            name
            isAPurchaser
            isASupplier
          }
          ... on Purchaser {
            name
            isAPurchaser
            isASupplier
          }
        }
        pricedCataloguedProducts(
          after: $after
          before: $before
          filter: $filter
          sort: $sort
          first: $first
          last: $last
        ) {
          edges {
            node {
              id
              supplierProductCode
              product {
                ...Product
                master
                barcodes {
                  edges {
                    node {
                      ...PurchasingBarcode
                    }
                  }
                }
                pricings {
                  edges {
                    node {
                      id
                      sellUnitPrice
                      updatedAt
                      sellUnitTaxPercentage
                    }
                  }
                }
              }
              image
              sellUnitPrice
              sellUnitTaxPercentage
              longDescription
            }
          }
          pageInfo {
            ...PurchasingPageInfo
          }
        }
        supplier {
          ...PurchasingSupplierDetailByLine
        }
      }
    }
  }
  ${ProductFragmentDoc}
  ${PurchasingBarcodeFragmentDoc}
  ${PurchasingPageInfoFragmentDoc}
  ${PurchasingSupplierDetailByLineFragmentDoc}
` as unknown as DocumentNode<Types.GetLegacyPricedCatalogueQuery, Types.GetLegacyPricedCatalogueQueryVariables>
