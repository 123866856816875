import { WatchQueryFetchPolicy, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { GetUserPreferenceDocument } from '@/graphql/access/generated/getUserPreference.generated'
import { GetLegacyPricedCatalogueDocument } from '@/graphql/purchasing/generated/getLegacyPricedCatalog.generated'
import { PricedCataloguedProduct, RansackMatcher } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useSession } from '@/modules/access/hooks/useSession'
import { getCatalogProductSortOptions } from '@/modules/catalogs/pages/catalog/products/table-view/getCatalogProductsSortOptions'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { QueryValues, useWatchQueryParams } from '@/modules/shared/hooks/useWatchQueryParams'
import { CATALOG_PRODUCTS_TABLE_NAME } from '@/modules/shared/table-names'
import { checkNetworkStatus } from '@/modules/shared/utils/checkNetworkStatus'
import { extractEdges } from '@/modules/shared/utils/extractEdges'
import { generateRansackSort } from '@/modules/shared/utils/generateRansackSort'
import { onFetchMore, onNextPage, onPreviousPage } from '@/modules/shared/utils/paginationUtils'

const generateCatalogProductsFilter = ({
  searchValue,
  masterProduct,
  categoryIds,
  pPlusProductCode,
  purchaserProductCode,
}: QueryValues): RansackMatcher[] => {
  const filter: RansackMatcher[] = []

  if (searchValue) {
    filter.push({
      property: 'product_search_text_fuzzy',
      value: searchValue.trim(),
    })
  }

  if (categoryIds && categoryIds.length > 0) {
    filter.push({
      property: 'product_categoryId_in',
      value: categoryIds,
    })
  }

  if (pPlusProductCode) {
    filter.push({
      property: 'product_id_eq',
      value: pPlusProductCode,
    })
  }

  if (purchaserProductCode) {
    filter.push({
      property: 'product_productCode_not_null',
      value: purchaserProductCode,
    })
  }

  if (masterProduct !== null && masterProduct !== undefined) {
    filter.push({
      property: 'product_master_eq',
      value: masterProduct,
    })
  }

  return filter
}

interface UseGetLegacyPricedCatalogProps {
  fetchPolicy?: WatchQueryFetchPolicy
}

export const useGetLegacyPricedCatalog = ({ fetchPolicy }: UseGetLegacyPricedCatalogProps) => {
  const { pricedCatalogueId } = useParams<{ pricedCatalogueId: string }>()

  const paginationResultsPerPage = 20
  const { searchValue, categoryIds, pPlusProductCode, purchaserProductCode, masterProduct } = useWatchQueryParams()
  const { currentUser } = useSession()
  const storageKey = `columns:${CATALOG_PRODUCTS_TABLE_NAME}:${currentUser?.id}`
  const { data: tableData, loading: tablePreferencesLoading } = useQuery(GetUserPreferenceDocument, {
    variables: { key: storageKey },
  })
  const defaultSort = getCatalogProductSortOptions().find((sortOption) => sortOption.isDefault)
  const { data, error, networkStatus, fetchMore, refetch } = useQuery(GetLegacyPricedCatalogueDocument, {
    variables: {
      first: paginationResultsPerPage,
      pricedCatalogueId: Number(pricedCatalogueId),
      after: null,
      filter: {
        q: generateCatalogProductsFilter({
          searchValue,
          categoryIds,
          pPlusProductCode,
          purchaserProductCode,
          masterProduct,
        }),
      },
      sort: generateRansackSort(tableData?.preference?.value, defaultSort),
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    notifyOnNetworkStatusChange: true,
    skip: tablePreferencesLoading || !pricedCatalogueId,
    fetchPolicy,
  })

  const onFetchMorePricedCatalogs = () => {
    fetchMore({
      variables: {
        after: data?.currentPurchaser?.pricedCatalogue?.pricedCataloguedProducts?.pageInfo.endCursor,
      },
    })
  }

  const pricedCatalog = data?.currentPurchaser?.pricedCatalogue

  const products = extractEdges<PricedCataloguedProduct>(pricedCatalog?.pricedCataloguedProducts)

  const loadingStates = checkNetworkStatus(networkStatus)
  const pageInfo = data?.currentPurchaser?.pricedCatalogue?.pricedCataloguedProducts?.pageInfo

  return {
    refetch,
    pricedCatalog,
    products,
    loadingStates,
    error,
    onFetchMorePricedCatalogs,
    pageInfo,
    onNextPage: () =>
      onNextPage({
        endCursor: pageInfo?.endCursor || null,
        refetch,
        paginationResultsPerPage,
      }),
    onPreviousPage: () =>
      onPreviousPage({
        startCursor: pageInfo?.startCursor || null,
        refetch,
        paginationResultsPerPage,
      }),
    onFetchMore: () =>
      onFetchMore({
        endCursor: pageInfo?.endCursor || null,
        fetchMore,
        paginationResultsPerPage,
      }),
    paginationResultsPerPage,
    tablePreferencesLoading,
  }
}
